import React from "react";
import { Suspencesizes } from "../constants";

function SuspenseLoading({ message = "Loading...", size = "large" }) {
  const spinnerSize = Suspencesizes[size] || Suspencesizes.large;

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div
        className={`flex items-center justify-center ${spinnerSize} border-t-4 border-[#074163] rounded-full animate-spin`}
      ></div>
      <p className="mt-4 text-lg text-gray-700 dark:text-gray-300 animate-pulse">
        {message}
      </p>
    </div>
  );
}

export default SuspenseLoading;
