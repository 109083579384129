export const LibraryDropdown = [
  { en: "All", ar: "الكل" },
  { en: "Report", ar: "تقرير" },
  { en: "Publication", ar: "اصدارات" },
  { en: "Other", ar: "اخرى" },
];

export const Suspencesizes = {
  small: "w-8 h-8 border-2",
  medium: "w-12 h-12 border-4",
  large: "w-16 h-16 border-8",
};

export const localization = {
  en: {
    conductor: "Conductor",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    daysOfWeek: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  },
  ar: {
    conductor: "الجهة المنفذة للورشة",
    months: [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    daysOfWeek: [
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
  },
};

export const Librarytitle = {
  ar: "مكتبة",
  en: "Library",
};
export const NewsPagetitle = { ar: "أخبار المنافسة", en: "Competition news" };
export const SubNewstitle = {
  en: "Article Title",
  ar: "عنوان المقال",
};

export const WorkShoptitle = {
  ar: "رزنامة ورش العمل",
  en: "Workshops Calendar",
};

export const AllEventstitle = {
  ar: "الأحداث والمؤتمرات",
  en: "Events & Meetings",
};

export const NotFoundcontent = {
  en: {
    title: "Oops! Page Not Found",
    description:
      "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
    homeButton: "Go to Homepage",
    backButton: "Go Back",
    searchPlaceholder: "Search our site...",
    searchButton: "Search",
  },
  ar: {
    title: "عذراً! الصفحة غير موجودة",
    description:
      "الصفحة التي تبحث عنها قد تكون أزيلت أو تغير اسمها أو غير متاحة مؤقتاً.",
    homeButton: "الذهاب إلى الصفحة الرئيسية",
    backButton: "العودة",
    searchPlaceholder: "ابحث في موقعنا...",
    searchButton: "بحث",
  },
};

export const EventFormtranslations = {
  en: {
    title: "Event Registration",
    firstName: "First Name",
    lastName: "Last Name",
    phonenumber: "Phone Number",
    email: "Email Address",
    message: "How can we help you?",
    submit: "Send",
    submitting: "Sending your message...",
    success: "Message sent successfully!",
    error: "Failed to send message. Please try again.",
    registerNow: "Register Now",
  },
  ar: {
    title: "تسجيل معنا الآن",
    firstName: "الاسم",
    lastName: "اسم العائلة",
    phonenumber: "رقم الهاتف",
    email: "البريد الإلكتروني",
    message: "كيف يمكننا مساعدتك؟",
    submit: "إرسال",
    registerNow: "سجل الآن",
    submitting: "جاري إرسال رسالتك...",
    success: "تم إرسال الرسالة بنجاح!",
    error: "فشل في إرسال الرسالة. يرجى المحاولة مرة أخرى.",
  },
};
